import consumer from "../consumer";

consumer.subscriptions.create("Notify::NotificationChannel", {
  connected() {
    console.log("Connected to NotificationChannel");
  },

  disconnected() {
    console.log("Disconnected from NotificationChannel");
  },

  received(data) {
    // Handle incoming data and update the UI
    const sound = new Audio('/bell.mp3');
    sound.play();

    window.vNotify.info({ text: data.title, title: data.message_title  });

    const notificationsCounter = document.getElementById("notify-unread-notification-count-badge");
    if (notificationsCounter) {
      const current_value = parseInt(notificationsCounter.innerText);
      notificationsCounter.innerText = current_value + 1;

      if (notificationsCounter.classList.contains("d-none")) {
        notificationsCounter.classList.remove("d-none");
      }
    }

    Notification.requestPermission().then(function(result) {
      if (result === 'granted') {
        const tittle = data.title;
        const namespace = data.namespace;
        new Notification(namespace, { body: tittle });
      }
    });
  }

});